.dashboard {
  height: 100%;
  .status-holder {
    display: flex;
    .status-element-holder {
      width: 25%;
      padding: 15px;
      .status-element {
        height: 100%;
        box-shadow: 2px 2px 7px 1px #efebeb;
        border-radius: 5px;
        background: white;
        height: 60px;
        padding: 15px;
        .status-header {
          color: #98aec7;
          font-size: 12px;
          font-weight: 600;
        }
        .status-value {
          color: #43588f;
          font-weight: 700;
          font-size: 30px;
          margin-top: 4px;
          padding-bottom: 10px;
          // border-bottom: 1px solid #edeff5;
        }
      }
    }
  }
  .filter-holder {
    display: flex;
    background: white;
    padding: 20px 15px;
    margin: 15px;
    .MuiFormControl-root {
      margin-left: 15px;
    }
  }
}
.id-holder {
  width: 550px;
}
.id-front,
.id-back {
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 1px #eae6e6cf;
  margin: 20px;
}
.id-header {
  text-align: center;
  background-color: #552f0e;
  color: white;
  height: fit-content;
  border-radius: 4px 4px 0 0;
  padding: 5px 0;
}
.id-header > h2,
.id-header > h5,
.address > p {
  margin: 0;
  letter-spacing: 0;
}
.address {
  text-align: center;
  border-bottom: 0.3px solid #4d4dab;
}
.address > p {
  color: #4d4dab;
  font-size: 11px;
  font-weight: 600;
}
.image-holder > img {
  width: 90%;
}
.image-holder {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-75 {
  width: 78%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 2%;
}
.id-values-holder {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.w-50 {
  width: 50%;
  margin: 10px 0;
}
.id-back > .id-values-holder > .w-50 {
  margin: 5px 0;
}
.w-100 {
  width: 100%;
}
.label {
  color: #626567;
  font-size: 12px;
}
.value {
  font-size: 14px;
  color: #4d4dab;
  margin-left: 5px;
}
.center {
  text-align: center;
}
.center.label {
  font-size: 14px;
  font-weight: 600;
}
.brd-btm {
  border-bottom: 0.5px solid #ebedef;
}
.pagination {
  display: flex;
  padding: 20px;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 3px;
    color: purple;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 10px;
    }
    &.break-me {
      border: none;
      background: none;
    }
    &.break-me:hover {
      border: none;
      background-color: #f6f7fc;
    }
    &.active {
        background-color: #040849;
        color: white;
        &:hover {
          background-color: #f6f7fc;
        }
      }
    &.nav {
        padding: 0 10px;
    }
  }

  

  .icon {
    border: lightgrey;
    &-selected {
      border: 1px solid #a4a5b5;
      background-color: #f6f7fc;
    }
    &:hover {
      border: lightgrey;
      background-color: #f6f7fc;
    }
  }
  .ellipses {
    border: lightgrey;
    &:hover {
      background-color: #ffffff;
    }
  }
  .disabled {
    opacity: 0.7;
    &:hover {
      background-color: #ffffff;
    }
  }
}
