.add-user {
  padding: 1em 2%;
  max-height: 80vh;
  // max-width: 1000px;
  margin: 0 auto;
}

.add-user .content-area {
  display: flex;
  flex-direction: column;
}

.add-user .content-area .form-navbar {
  width: 100%;
  margin-bottom: 1em;
}

.add-user .content-area .form-navbar .navbar-info {
  color: #a5a5a5;
  font-size: 0.8em;
  padding-bottom: 0.5em;
  border-bottom: 0.5px solid #ececec;
}

.add-user .content-area .form-navbar .csv-dropzone,
.add-user .content-area .form-navbar .csv-dropzone-solid {
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(221, 221, 221);
}

.add-user .content-area .form-navbar .csv-dropzone img,
.add-user .content-area .form-navbar .csv-dropzone-solid img {
  max-width: 80%;
  max-height: 80%;
}

.add-user .content-area .form-navbar .csv-dropzone-solid .close {
  background: #3f4054;
  padding: 0.4em;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

.add-user .content-area .form-navbar .csv-dropzone-solid .close .c-icon {
  width: 1em;
  height: 1em;
}

.add-user .content-area .form-navbar .csv-dropzone .drop-zone {
  padding: 2em 1em;
}

.add-user .content-area .form-navbar .csv-dropzone .drop-zone .upload-instruction {
  font-size: 0.8em;
  margin-top: 0.5em;
}

.add-user .content-area .form-area {
  width: 100%;
  padding: 0 0.5em;
  overflow-y: auto;
  max-height: 80vh; /* Default max-height */
}

.add-user .content-area .form-area .form-holder {
  background: white;
  padding: 1em;
  box-shadow: 1px 1px 5px 1px #eae6e6cf;
  margin-bottom: 1em;
  height: fit-content;
}

.add-user .content-area .form-area .form-holder .form-holder-header {
  color: #46305c;
  font-weight: 600;
  font-size: 0.9em;
  padding-bottom: 0.8em;
  border-bottom: 0.5px solid #efefef;
  margin-bottom: 0.5em;
}

.add-user .content-area .form-area .form-holder .MuiFormControl-root {
  width: 100%;
  margin: 0.5em 0;
}

.add-user .content-area .form-area .form-holder .MuiFormLabel-root {
  color: #959595;
  font-size: 0.8em;
}

.add-user .content-area .form-area .form-holder .add-extra-value {
  width: 100%;
  text-align: right;
}

.add-user .content-area .form-area .form-holder .add-extra-value button {
  float: right;
}

.add-user .button-holder {
  width: 100%;
  margin-top: 1em;
  text-align: center;
}

.add-user .id__card {
  text-align: center;
  margin-top: 0.5em;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  width: 100%;
}

@media (min-width: 768px) {
  .add-user {
    padding: 10px 2%;
    height:80vh
  }

  .add-user .content-area {
    flex-direction: row;
  }

  .add-user .content-area .form-navbar {
    width: 30%;
    margin-bottom: 0;
  }

  .add-user .content-area .form-area {
    width: 80%;
    padding: 0 20px;
  }

  .add-user .content-area .form-area .form-holder .MuiFormControl-root {
    width: 48%;
    margin: 2% 1%;
  }

  .add-user .button-holder {
    width: 70%;
    margin-left: 30%;
    margin-top: 1em;
  }

  .add-user .id__card {
    width: 70%;
  }
}

/* New media queries */
@media (min-width: 320px) and (max-width: 700px) {
  .add-user .content-area .form-area {
    max-height: 49vh;
  }
}

@media (min-width: 1440px) {
  .add-user .content-area .form-area {
    max-height: 80vh;
  }
}