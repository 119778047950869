.user-detail{
    margin: 5px 15px ;
    .breadcrumb {
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 12px;
        border-bottom: 0.5px solid #e8e3e3;
        align-items: center;
        .breadcrumb-value {
            align-items: center;
            width: 30%;
            display: flex;
            a {
                text-decoration: none;
                color: #6459ff;
            }
            .c-icon.next-icon{
                margin-left: 8px;
            }
            .username {
                color: #77797b;
                position: relative;
                left: -4px;
            }
        }
        .tools-holder {
            width: 70%;
            align-items: center;
            .edi-profile {
                float: right;
                display: flex;
                padding: 2px 11px;
                font-size: 11px;
                color: #77797b;
                font-weight: 600;
                .c-icon {
                    font-size: 16px;
                }
            }
        }
    }
    .user-content-holder {
        padding: 20px 0;
        display: flex;
        .profile-holder {
            .profile-image-card {
                color: #273547;
                height: 300px;
                text-align: center;
                .image-holder {
                    margin-left: 25%;
                    margin-top: 20px;
                    img {
                        width: 115px;
                        height: 115px;
                    }
                }
                .profile-name {
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 10px;
                }
                .occupation-data{
                    margin: 20px 0;
                    font-weight: 600;
                    .first-child {
                        box-sizing:border-box;
                        border-right: 0.5px solid #d6d4d4;
                    }
                }
                .side-header {
                    color: #b4bac1;
                    font-size: 14px;
                    margin-top: 2px;
                    font-weight: 400;
                }
            }
            .meta-header {
                color: #98a2ab;
                font-size: 12px;
            }
            .meta-value {
                font-size: 14px;
                color: #525b67;
            }
            .profile-details-card {
                margin-left: 1%;
                padding-left: 25px;
                .meta-holder {
                    height: 60px;
                    font-weight: 600;
                    margin-top: 19px;
                    
                    .border-bottom {
                        border: 0.5px solid #ececec85;
                        margin-top: 20px;
                    }
                }
                
            }
            .user-log-card {
                margin-top: 15px;
                height: 300px;
                .toggle-holder {
                    background: #f0f1f7;
                    border-radius: 3px;
                    padding: 4px;
                    width: fit-content;
                    margin-top: 5px;
                    height: 24px;
                    .toggle-button {
                        width: fit-content;
                        padding: 3px 10px;
                        font-size: 12px;
                        border-radius: 3px;
                        margin-right: 5px;
                        color: #8d9398;
                        cursor: pointer;
                        &.selected {
                            color: #447bfc;
                            background: white;
                        }
                    }

                }
                .user-log-details {
                    background: #f0f1f7;
                    height: 224px;
                    margin-top: 15px;
                    border-radius: 3px;
                    padding: 0 10px;
                    box-sizing:border-box;
                    overflow: scroll;
                    .log-holder{
                        height: 100px;
                        .side-line-holder{
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .line {
                                height: inherit;
                                border: 1px solid #4382fa;
                                box-sizing: border-box;
                                width: 1px;
                                position: relative;
                                left: -8px;
                            }
                            .circle{
                                width: 10px;
                                height: 10px;
                                background: #f0f1f7;
                                border-radius: 10px;
                                border: 2px solid #4382fa;
                                z-index: 10;
                            }
                        }
                        .log-data-holder{
                            padding: 10px 0;
                            .log-data-card {
                                height: 100%;
                                border-radius: 0;
                                .log-value-holder{
                                    padding: 5px 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .other-holder{
            padding-left: 15px;
            min-height: 300px;
            .files-card{
                .files-header{
                    font-weight: 600;
                    .add-file{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}